import ReactDatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import PropTypes from "prop-types";
import React from "react";

export const DatePicker = ({
  open,
  name,
  value,
  format,
  errors,
  minDate,
  touched,
  disabled,
  onChange,
  className,
  placement,
  errorHooks,
  customInput,
  placeholder,
  defaultValue,
  wrapperClassName,
  showYearDropdown,
}) => {
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="datepicker_input-wrapper">
      <MaskedTextInput
        type="text"
        mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        value={value}
        onClick={onClick}
        ref={ref}
        className="form-control"
      />
      <i className="air ai-calendar" onClick={onClick} />
    </div>
  ));
  return (
    <ReactDatePicker
      name={name}
      popperPlacement={placement}
      dateFormat={format}
      disabled={disabled}
      placeholderText={placeholder}
      selected={value || defaultValue}
      showYearDropdown={showYearDropdown}
      wrapperClassName={wrapperClassName}
      className={`form-control ${className} ${
        (touched && errors && touched[name] && errors[name]) || errorHooks
          ? "error"
          : ""
      }`}
      onChange={onChange}
      customInput={<CustomInput />}
    />
  );
};

DatePicker.propTypes = {
  open: PropTypes.bool,
  name: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  customInput: PropTypes.object,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
  showYearDropdown: PropTypes.bool,
};

DatePicker.defaultProps = {
  name: "",
  className: "",
  placeholder: "",
  wrapperClassName: "",
  format: "dd/MM/yyyy",
  showYearDropdown: true,
  open: false,
  customInput: (
    <MaskedTextInput
      type="text"
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
    />
  ),
};
