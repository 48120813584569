import React from "react";

export const Checkbox = ({
  id,
  value,
  onChange,
  label,
  handleChange,
  setValue,
}) => {
  return (
    <div
      className="checkbox"
      onClick={() =>
        handleChange ? handleChange(!value, setValue) : onChange(!value)
      }
    >
      <input
        type="checkbox"
        // className="form-check-input"
        id={id}
        checked={value}
        // onChange={onChange}
      />
      <span class="checkmark"></span>
      <label>{label}</label>
    </div>
  );
};
