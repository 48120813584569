import NextLink from "next/link";
import React from "react";

const SITE_URL = process.env.SITE_URL || process.env.NEXT_PUBLIC_SITE_URL;

export default function Link({ href, target, children, className, ...props }) {
  if (href && href.startsWith("/")) {
    return (
      <NextLink href={href} className={className} {...props}>
        {children}
      </NextLink>
    );
  }

  if (href && href.includes(SITE_URL)) {
    const url = href.split(SITE_URL);

    return (
      <NextLink href={url[0] ?? "/"} className={className} {...props}>
        {children}
      </NextLink>
    );
  }

  return (
    <a
      {...props}
      className={className}
      href={href}
      target={target}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

Link.defaultProps = {
  href: "/",
};
