import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";

export const Modal = ({
  id,
  isShowing,
  hide = () => {},
  className,
  img,
  wrapperClassName,
  bodyClassName,
  imgClassName,
  title,
  contentClassName,
  titleClassName,
  text,
  textClassName,
  children,
  closeClassName,
  size,
}) => {
  return (
    <ReactModal
      closeTimeoutMS={500}
      isOpen={isShowing === id && true}
      contentLabel="Modal"
      onRequestClose={hide}
      className={`modal container ${size} ${className}`}
      ariaHideApp={false}
    >
      <div className={`modal-wrapper ${wrapperClassName}`}>
        <div
          onClick={hide}
          className={`close ${closeClassName}`}
          role="button"
          tabIndex={0}
        >
          <i className="ail ai-times" />
        </div>
        <div className={`modal-body ${bodyClassName}`}>
          {img && (
            <img
              src={img}
              className={`img-fluid w-100 ${imgClassName}`}
              alt={imgAlt}
            />
          )}
          <div className={`modal-content ${contentClassName}`}>
            {title && (
              <h3 className={`modal-title ${titleClassName} fadeInUp d1`}>
                {title}
              </h3>
            )}
            {text && (
              <p className={`modal-text ${textClassName} fadeInUp d2`}>
                {text}
              </p>
            )}
            {children}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;

Modal.propTypes = {
  img: PropTypes.any,
  imgClassName: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  className: PropTypes.string,
  closeClassName: PropTypes.string,
  children: PropTypes.any,
};

Modal.defaultProps = {
  imgClassName: "",
  imgAlt: "Modal Image",
  titleClassName: "",
  textClassName: "",
  wrapperClassName: "",
  bodyClassName: "",
  contentClassName: "",
  className: "",
  closeClassName: "",
};
