// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be used flexibly. But if you REALLY MUST update edit the component,
// please add `-custom` on the change log file (e.g. `1.0.0-custom.md`) and log the changes

// ==========================================================================
// Forms - Input
// ==========================================================================

import React from "react";
import PropTypes from "prop-types";

import ReactTooltip from "react-tooltip";
import { useState } from "react";
import { Image } from "../../Image";

export const HookInput = ({
  id,
  name,
  variant,
  theme,
  as,
  type,
  label,
  placeholder,
  values,
  formText,
  onChange,
  onBlur,
  errors,
  // touched,
  setValue,
  inputMode,
  rows,
  iconRight,
  iconLeft,
  floatingLabel,
  className,
  wrapperClassName,
  labelClassName,
  inputClassName,
  children,
  disabled,
  hideValidationText,
  register,
  required,
  textLeft,
  textRight,
  validation,
  tooltip,
  maxLength,
  showPassword,
  textLeftAlt,
  phoneBarcode,
  prefix,
  ...props
}) => {
  const fieldVariant = () => {
    if (variant === "underline") {
      return "field-underline";
    } else {
      return "field-basic";
    }
  };
  let validationState =
    type === "email"
      ? {
          ...validation,
          pattern: {
            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: "Invalid email format",
          },
        }
      : { ...validation };
  const [passwordState, setPasswordState] = useState("password");
  return (
    <div
      className={`field ${fieldVariant()}
                ${theme}
                ${floatingLabel ? "field-floating" : ""}
                ${errors && errors[name] ? "is-invalid" : ""}
                ${className} `}
    >
      {label && (
        <label className={labelClassName} htmlFor={id || name}>
          {label}
        </label>
      )}

      {as === "input" && (
        <div className="d-flex align-items-center">
          {textLeftAlt && <span className="d-block mr-2">{textLeftAlt}</span>}
          <div
            className={`form-control-wrapper w-100
                        ${prefix ? "prefix" : ""}
                        ${iconRight ? "ic-right" : ""}
                        ${iconLeft ? "ic-left" : ""}
                        ${wrapperClassName}`}
          >
            {/* {textLeft && (
              <span className="input-left text-muted">{textLeft}</span>
            )} */}
            <div className="input wrapper">
              {prefix ? <span className="prefix-content">{prefix}</span> : null}
              <input
                id={id}
                name={name}
                type={showPassword ? passwordState : type}
                placeholder={placeholder}
                className={`form-control ${inputClassName} ${
                  errors[name] ? "border-error" : ""
                }`}
                inputMode={type === "number" ? "tel" : ""}
                disabled={disabled}
                maxLength={maxLength}
                onWheel={(e) => {
                  if (type === "number") {
                    e.target.blur();
                  }
                }}
                {...register(name, {
                  onChange: (e) => {
                    if (onChange) {
                      onChange(name, e.target.value, setValue);
                    }
                  },
                  required: {
                    value: required,
                    message: "Field Required",
                  },
                  ...validationState,
                })}
              />
            </div>
            {floatingLabel && (
              <label className={labelClassName} htmlFor={id}>
                {label}
              </label>
            )}
            <i className="el-underline" />
            {children}
            {showPassword && (
              <div className="show-pass-icon">
                {/* <Image src="/img/icon/eye.svg" width={20} height={20} /> */}
                {passwordState === "password" ? (
                  <i
                    className="ail ai-eye"
                    onClick={() => setPasswordState("text")}
                  />
                ) : (
                  <i
                    className="ail ai-eye-slash"
                    onClick={() => setPasswordState("password")}
                  />
                )}
              </div>
            )}
            {textRight && <p className="input-right text-muted">{textRight}</p>}
            {errors[name] ? (
              <div className="mt-1">
                <p className="error-message text-danger m-0">
                  {/* <b>{name}</b> tidak boleh kosong */}
                  {errors[name]?.message}
                </p>
                {/* <i data-tip={tooltip} className="tooltip-icon" />
                <ReactTooltip place="bottom" backgroundColor="#a1a1a1" /> */}
              </div>
            ) : null}
          </div>
        </div>
      )}

      {as === "textarea" && (
        <div
          className={`form-control-wrapper
                            ${iconRight ? "ic-right" : ""}
                            ${iconLeft ? "ic-left" : ""}
                            ${wrapperClassName}`}
        >
          <textarea
            id={id}
            name={name}
            placeholder={placeholder}
            className={`form-control ${inputClassName}`}
            // value={value}
            // onBlur={onBlur}
            // onChange={e => onChange(name, e.target.value)}
            disabled={disabled}
            rows={rows}
            inputMode={inputMode}
            type={type}
            maxLength={maxLength}
            {...register(name, {
              onChange: (e) => {
                if (onChange) {
                  onChange(name, e.target.value);
                }
              },
              required: {
                value: required,
                message: "Field ini tidak boleh kosong",
              },
              ...validation,
            })}
          />
          {floatingLabel && (
            <label className={labelClassName} htmlFor={id}>
              {label}
            </label>
          )}
          <i className="el-underline" />
          {children}
        </div>
      )}
      {!hideValidationText && (
        <>
          {errors && errors[name] && (
            <div className="invalid-feedback">{errors[name].message}</div>
          )}
        </>
      )}
      {formText && <div className="form-text">{formText}</div>}
    </div>
  );
};

HookInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  variant: PropTypes.oneOf(["basic", "underline"]),
  theme: PropTypes.oneOf(["light", "dark"]),
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  as: PropTypes.string,
  value: PropTypes.string,
  floatingLabel: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
};

HookInput.defaultProps = {
  id: "",
  type: "text",
  variant: "basic",
  theme: "light",
  label: null,
  as: "input",
  placeholder: "Enter text",
  required: false,
  className: "",
  labelClassName: "",
  inputClassName: "",
  wrapperClassName: "",
  hideValidationText: false,
  disabled: false,
};
